<template>
    <v-container fluid style="margin-bottom: 100px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
            <v-col cols="12" xs="12" sm="12" md="12">
                <!-- <v-card> -->
                    <v-card-title class="pt-1 pb-1">
                        Filter
                    </v-card-title>
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Office ID"
                                    @change="(event) => updateSales(event)"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    :disabled="disabled"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="sales"
                                    :items="saless"
                                    item-value="sales_id"
                                    item-text="nama"
                                    label="Sales"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    :disabled="disabled"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    v-model="customer"
                                    :loading="loadingCust"
                                    :items="items"
                                    item-value="customer_id"
                                    item-text="nama"
                                    :search-input.sync="search"
                                    cache-items
                                    hide-no-data
                                    label="Customer"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-text-field
                                    solo
                                    v-model="order_id"
                                    label="Order ID"
                                    single-line
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Date From"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="Date To"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="outstanding"
                                    :items="outstandings"
                                    item-value="value"
                                    item-text="label"
                                    label="Outstanding Order"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="submit(1,10)">Search</v-btn>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="5" md="3" class="mt-1">
                                <v-btn
                                    class="mr-2"
                                    color="error"
                                    elevation="2"
                                    large
                                    rounded
                                    @click="submit()"
                                >
                                    <v-icon dark>
                                        mdi-magnify
                                    </v-icon>
                                    Search
                                </v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
                <!-- </v-card> -->
            </v-col>
            <v-col cols="12" class="mt-1 mb-1">
                <v-divider color="#000" class="mt-1 mb-1"></v-divider>
            </v-col>
        </v-row>
        <v-row v-show="display">
            <v-col cols="12" style="padding-bottom: 100px">
                <v-skeleton-loader v-if="loading_loader" :loading="loading_loader" type="card, table"></v-skeleton-loader>
                <div v-show="loading_loader === false">
                    <v-container fluid>
                        <v-row>
                            <v-card-title>Result
                            </v-card-title>
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <v-data-table
                                    :headers="headers"
                                    :items="raw_materials"
                                    :options.sync="options"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`header.dt_trans`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.cust_name`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.order_id`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.po_id`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.due_date`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.dt_po`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.dt_release`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.dt_rcv`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.salesman`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.dt_approved`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.dt_closed`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
                <v-dialog v-model="dialog" max-width="1400px">                
                    <v-card>
                        <v-card-title>Detail</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table
                                            :headers="header_details"
                                            :items="debt_details"
                                            :options.sync="option_details"
                                            :loading="loading_details"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItem_details"
                                            :page.sync="pagination_details"
                                            page-count="10"
                                            class="elevation-1"    
                                        >
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'KBT',
                disabled: false,
                href: '/admin/srmall',
                },
                {
                text: 'KBT Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Order Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading:false,
            offices: [],
            office: '',
            saless:[],
            sales: '',
            customers:[],
            items:[],
            customer: '',
            loadingCust: false,
            display: false,
            loading_loader: false,
            sortBy: 'asc',
            sortName: '',
            raw_materials: [],
            search: null,
            headers: [
                {
                    text: 'Date Order',
                    align: 'start',
                    sortable: false,
                    value: 'dt_trans',
                },
                { text: 'Customer', value: 'cust_name', sortable: false },
                { text: 'Order ID', value: 'order_id', sortable: false },
                { text: 'PO ID', value: 'po_id', sortable: false },
                { text: 'Amt Pay', value: 'amt_pay', sortable: false },
                { text: 'Due Date', value: 'due_date', sortable: false },
                { text: 'PO Date', value: 'dt_po', sortable: false },
                { text: 'Release Date', value: 'dt_release', sortable: false },
                { text: 'Rcv Date', value: 'dt_rcv', sortable: false },
                { text: 'Sales', value: 'salesman', sortable: false },
                { text: 'Stat', value: 'stat', sortable: false },
                { text: 'Apprv Date', value: 'dt_approved', sortable: false },
                { text: 'Closed Date', value: 'dt_closed', sortable: false },
                { text: 'Ship To', value: 'ship_to', sortable: false },
                { text: 'Action', value: 'action', sortable: false }
            ],
            totalItems: 10,
            pagination: 1,
            options: {},
            order_id: '',
            outstandings: [
                {
                    label: 'Yes',
                    value: 'Y'
                },
                {
                    label: 'No',
                    value: 'N'
                }
            ],
            outstanding: 'Y',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            invoice: '',
            summaryInv: {},
            dialog: false,
            detailItem:{},
            header_details:[
                { text: 'Item Num', value: 'item_num', sortable: false },
                { text: 'SP', value: 'kka_sp', sortable: false },
                { text: 'Article', value: 'article_desc', sortable: false },
                { text: 'Ord Qty', value: 'order_qty', sortable: false },
                { text: 'Ord Ship', value: 'order_shipped', sortable: false },
                { text: 'Ord Retur', value: 'order_retur', sortable: false },
                { text: 'PO Qty', value: 'po_qty', sortable: false },
                { text: 'PO Rcv', value: 'po_rcv', sortable: false },
                { text: 'PO Retur', value: 'po_retur', sortable: false },
                { text: 'Kka Ord', value: 'kka_order', sortable: false },
                { text: 'Kka Plan', value: 'kka_plan', sortable: false },
                { text: 'Kka Prod', value: 'kka_prod', sortable: false },
                { text: 'Kka Wh', value: 'kka_wh', sortable: false },
                { text: 'Kka Ship', value: 'kka_ship', sortable: false },
                { text: 'Kka Retur', value: 'kka_returr', sortable: false },
                { text: 'Kka Replace', value: 'kka_replace', sortable: false },
                { text: 'Kka Stat', value: 'kka_stat', sortable: false }
            ],
            debt_details: [],
            option_details: {},
            loading_details: false,
            totalItem_details: 10,
            pagination_details: 1,
            data_var: {
                entity_id : 'KBT',
                appl_id : 'WEBKBT'
            },
            group: '',
            disabled: false
        }
    },
    mounted(){
        this.getMaterial()
        this.getEnvConf()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'OFFICEID') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.office = res.data.data[i]['var_value']
                            this.getMaterial()
                            this.disabled = true
                        }
                            
                    }

                    if (res.data.data[i]['var_id'] === 'SALESID') {

                        if (res.data.data[i]['var_value'] != null) {
                            this.sales = res.data.data[i]['var_value']
                            this.updateSales()
                            this.disabled = true
                        }
                    }

                    
                }

            })
        },
        async updateSales(office){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/piutang?officeid=${this.office ? this.office : 'office'}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.saless = res.data.sales
            });
        },
        async querySelections (value) {
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/customer?search=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.items = res.data
            });
        },
        close(){
            this.dialog = false
        },
        async getMaterial(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/piutang`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data.office
                this.saless = res.data.sales
            });
        },
        sortByFunc(sortBy){
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.submit(1, 10)
        },
        async submit(page = 1, itemsPerPage = 10){
            this.loading = true
            this.loading_loader = true
            this.display = false
            this.$store.dispatch('setOverlay', true)


            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            if (start_date === '') {
                this.$store.dispatch('setOverlay', false)

                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please select start date",
                    visible: true
                };
            }

            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/order_report?txtOfficeID=${this.office ? this.office : ''}&txtSalesID=${this.sales ? this.sales : ''}&txtOrderID=${this.order_id ? this.order_id : ''}&txtCustomer=${this.customer ? this.customer : ''}&txtOutstanding=${this.outstanding ? this.outstanding : ''}&txtStart=${start_date ? start_date : ''}&txtEnd=${end_date ? end_date : ''}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.loading_loader = false
                this.display = true
                this.$store.dispatch('setOverlay', false)

                this.raw_materials = res.data.data
                this.totalItems = res.data.total
                this.pagination = res.data.current_page
            })
        },
        showItem(item){
            this.dialog = true
            this.detailItem = item
            this.debt_details = []
            this.getDetail(1, 10, item)
        }, 
        async getDetail(page = 1, itemsPerPage = 10, item){
            this.loading_details = true
            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/order_report/detail?txtOrderID=${this.detailItem.order_id ? this.detailItem.order_id : ''}&txtPOID=${this.detailItem.po_id ? this.detailItem.po_id : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_details = false

                this.debt_details = res.data.data
                this.totalItem_details = res.data.total
                this.pagination_details = res.data.current_page
            })
        }
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display === true) {
                    this.submit(page, itemsPerPage)
                }
            },
            deep: true
        },
        option_details:{
            handler () {
                const { page, itemsPerPage } = this.option_details
                if (this.detailItem) {
                    this.getDetail(page, itemsPerPage, this.detailItem)
                }
            },
            deep: true
        },
        search (val) {
            val && val !== this.customer && this.querySelections(val)
        },
    }
}
</script>